import { graphql, useStaticQuery, Link } from "gatsby";
import React, { useState, useEffect } from "react";

import logo from "../images/logo_orange.png";
import logoSw from "../images/logo_sw.png";
import buttonBurgerW from "../images/button-burger2_w.png";
import buttonBurgerO from "../images/button-burger2_o.png";
import closeO from "../images/close_o.png";

function Header() {
  const [isExpanded, toggleExpansion] = useState(false);
  const [isBgWhite, setIsBgWhite] = useState(false);

  const { site } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  useEffect(() => {
    const handleScroll = () => {
      if (document.querySelector(".hero-face")) {
        setIsBgWhite(
          window.pageYOffset >
            document.querySelector(".hero-face").offsetHeight - 50
        );
      }
      if (document.querySelector(".hero-graph")) {
        setIsBgWhite(
          window.pageYOffset >
            document.querySelector(".hero-graph").offsetHeight - 50
        );
      }
      if (document.querySelector(".hero-chat")) {
        toggleExpansion(!isExpanded);
      }
    };

    if (isExpanded) {
      document.querySelector("main").style.filter = "blur(10px)";
    } else {
      document.querySelector("main").style.filter = "blur(0px)";
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  return (
    <div>
      <header className="fixed z-20 w-full">
        <div className="mx-auto max-w-6xl py-8 px-6 md:px-12 xl:px-0 flex justify-between items-center">
          <div className="logo">
            <Link
              className="flex justify-center items-center text-white h-full no-underline"
              to="/"
            >
              <div className="relative logo-wrapper w-13 h-13 sm:w-15 sm:h-15">
                <img
                  src={logo}
                  alt="logo"
                  className="relative z-20 logo-image"
                />
                <img
                  src={logoSw}
                  alt="logo"
                  className={`${
                    isBgWhite || isExpanded ? "hidden" : "block"
                  } absolute z-10 logo-shadow`}
                />
              </div>
              <div>
                <div
                  className={`${
                    isBgWhite || isExpanded ? "opacity-0" : "opacity-1"
                  } logo-text text-xl sm:text-2xl w-full transition-opacity duration-1000`}
                >
                  {site.siteMetadata.title}
                </div>
              </div>
            </Link>
          </div>

          <div>
            <button
              className={`${
                isExpanded ? "hidden" : "block"
              } menu-open focus:outline-none`}
              onClick={() => toggleExpansion(!isExpanded)}
            >
              <img
                src={isBgWhite ? buttonBurgerO : buttonBurgerW}
                alt="menu icon"
                className="w-8 h-8 sm:w-9 sm:h-9"
              />
            </button>

            <button
              className={`${
                isExpanded ? "block" : "hidden"
              } menu-close focus:outline-none`}
              onClick={() => toggleExpansion(!isExpanded)}
            >
              <img
                src={closeO}
                alt="menu icon"
                className="w-8 h-8 sm:w-9 sm:h-9"
              />
            </button>
          </div>
        </div>
      </header>

      <div
        className={`${
          isExpanded ? "opacity-1" : "opacity-0 pointer-events-none"
        } transition-opacity duration-700 h-full w-full fixed z-10 top-0 left-0 bg-gray-300 flex items-center justify-center`}
        style={{ backgroundColor: "hsla(0, 0%, 100%, .8)" }}
      >
        <nav>
          <ul className="grid grid-flow-row xl:grid-flow-col w-full mx-auto text-brand-gray">
            <li className="nav-link">
              <Link to="/about" className="text-black">
                Über mich
              </Link>
            </li>
            <li className="nav-link border-0">
              <Link to="/" className="text-black">
                Anfrage
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default Header;
