import PropTypes from "prop-types";
import React from "react";

import Header from "./header";

function Layout({ children }) {
  return (
    <div className="antialiased flex flex-col font-sans h-full text-sm leading-5 font-light">
      <Header />

      <main className="w-full flex flex-col">{children}</main>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
